const menu_data = [
  {
    id: 1,
    mega_menu: false,
    has_dropdown: false,
    title: "ANASƏHİFƏ",
    link: "/",
    active: "active",
  },
  // {
  //   id: 2,
  //   mega_menu: false,
  //   has_dropdown: false,
  //   title: "İMTAHAN",
  //   link: "/quiz-play",
  //   active: "",
  // },
  // {
  //   id: 3,
  //   mega_menu: false,
  //   has_dropdown: false,
  //   title: "İŞARƏLƏDİKLƏRİM",
  //   link: "/bookmark",
  //   active: "",
  // },
  {
    id: 4,
    mega_menu: false,
    has_dropdown: false,
    title: "DOSTUNU DƏVƏT ET",
    link: "/invite-friends",
    active: "",
  },
  {
    id: 5,
    mega_menu: false,
    has_dropdown: false,
    title: "TƏLİMAT",
    link: "/instruction",
    active: "",
  },
  {
    id: 6,
    mega_menu: false,
    has_dropdown: false,
    title: "DAHİLƏRİMİZ",
    link: "/leaderboard",
    active: "",
  },
  {
    id: 7,
    mega_menu: false,
    has_dropdown: true,
    title: "ƏTRAFLI",
    link: "/",
    active: "",
    sub_menus: [
      { link: "/contact-us", title: "ƏLAQƏ" },
      { link: "/about-us", title: "HAQQIMIZDA" },
      { link: "/terms-conditions", title: "ŞƏRTLƏR VƏ QAYDALAR" },
      { link: "/privacy-policy", title: "GİZLİLİK VƏ SİYASƏT" },
    ],
  },
];
export default menu_data;
